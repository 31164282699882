import { render, staticRenderFns } from "./DutyCenter.vue?vue&type=template&id=a184e3ba&scoped=true"
import script from "./DutyCenter.vue?vue&type=script&lang=js"
export * from "./DutyCenter.vue?vue&type=script&lang=js"
import style0 from "./DutyCenter.vue?vue&type=style&index=0&id=a184e3ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a184e3ba",
  null
  
)

export default component.exports