var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-inner flex p-relative",class:{'events-none': _vm.disabled}},[(_vm.showLabel)?_c('div',{staticClass:"label"},[_c('span',[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_c('div',{staticClass:"flex flex-1",class:{
            'not-allowed': _vm.disabled
         },staticStyle:{"align-items":"end"}},[(_vm.rowInfo.value)?_c('div',{staticClass:"show-image bg-no-repeat bg-center bg-size-cover",style:({
                backgroundImage: `url(${_vm.fileUrl})`
            })},[_c('span',{staticClass:"delete-mask d-inline-block w-100 h-100 text-center"},[_c('i',{staticClass:"el-icon-delete font-16 text-white",on:{"click":_vm.deletePic}})])]):_c('div',{staticClass:"upload-div p-relative"},[_vm._m(0),_c('input',{key:_vm.updateKey,staticClass:"d-inline-block opacity-0 w-100 h-100",attrs:{"type":"file"},on:{"change":_vm.beforeSubmit}})]),_vm._m(1)]),(_vm.showError)?_c('span',{staticClass:"p-absolute error font-12"},[_vm._v("直播封面不能为空")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"upload-button text-center p-absolute w-100 h-100 d-inline-block"},[_c('i',{staticClass:"el-icon-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-column m-l-15"},[_c('span',{staticClass:"font-14 text-d8"},[_vm._v("(友情提示：仅支持jpg, jpeg, png)")]),_c('span',{staticClass:"font-14 m-t-10 text-d8"},[_vm._v("推荐图片封面尺寸为 340 * 190")])])
}]

export { render, staticRenderFns }